import { Authority } from '~/src/enums/Authority'
import { GET_CLIENT_USER } from '~/composables/queries/useClientUser'

interface AuthMeta {
  authority: Authority
}

export default defineNuxtRouteMiddleware(async (to) => {
  const user = useSupabaseUser()

  const client = useApi()

  const localePath = useLocalePath()

  const differentRoute = (route: string) => {
    return to.path !== localePath(route)
  }

  if (to.path === localePath('reset')) {
    return
  }

  if (!user.value && differentRoute('sign-in')) {
    return navigateTo(localePath('sign-in'))
  }

  const authority: Authority = to.meta.auth ? (to.meta.auth as AuthMeta).authority as Authority : Authority.Completed

  let surveyCompleted = user.value!.user_metadata.surveyCompleted

  if (surveyCompleted) {
    try {
      // We can't re-use ClientUser composable here. It just breaks reactivity of the query store. I will postpone this thing now, because it's hard to resolve
      const { data } = await client.GET(GET_CLIENT_USER, {
        params: {
          path: {
            id: user.value!.id
          }
        }
      })

      surveyCompleted = data?.currentCampaign || data?.currentMediaProject || false
    } catch (e) {
      console.error(e)
    }
  }

  if (surveyCompleted && authority === Authority.Completed) {
    return
  }

  if (!surveyCompleted && differentRoute('survey')) {
    return navigateTo(localePath('survey'))
  }
})
